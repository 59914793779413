import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@zpancakeswap-libs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

// TODO: Replace in ui-kit!
const GlobalStyle = createGlobalStyle`
  * {
    font-family: inconsolata,monospace;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  ::-webkit-scrollbar-thumb {
    background: #ff7e79!important;
  }
  a {
    color: #011993;
  }
  a[variant="subtle"] {
    background-color: #ff7e79;
  }
  div[color="text"],span[color="text"],div[color="textSubtle"],span[color="textSubtle"], li {
    /*color: #011993!important;*/
    font-family: inconsolata,monospace;
  }
  /*button:not([disabled]), a div, div div  {
    color: #011993!important;
  }*/
  button:not([disabled]) svg, a svg, div div svg {
    fill: #011993!important;
  }
  /*div div a {
    border-color: #011993!important;
  }*/
  .bhLgjS {
    background-color: #ff7e79!important;
  }
  .fYMFcw {
    border-top:2px solid #011993!important; 
  }
  h1[color="text"], h2[color="text"] {
    /*color: #ff7e79!important;
    text-shadow: 0.088rem 0.088rem 0 #011993;*/
    font-family: 'VT323', monospace;
    letter-spacing: 0.1em;
  }
  .eHVNrW .bGHila {
    /*Hide darkmode toggle*/
    display:none;
  }
`

export default GlobalStyle
